import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Button,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { Enrolment } from "../../models/Enrolment";
import PrimaryTable from "../../components/Tables/PrimaryTable";
import Loader from "../../components/Loader";
import {
  EnrolmentsApi,
  enrolmentsHeadCells,
} from "../../backendIntegration/EnrolmentsApi";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Enrolments() {
  const [data, setData] = useState<Enrolment[]>();

  const enrolmentsApi = useMemo(() => new EnrolmentsApi(), []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await enrolmentsApi.getEnrolments();
      setData(response);
    };
    fetchData();
  }, [enrolmentsApi]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Enrolment[]>();

  const doSearchEnrolments = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (data && searchValue) {
      const d = data.filter((enrolment: Enrolment) => {
        const stringToSearch = `${enrolment.email} ${enrolment.firstName} ${enrolment.surname} ${enrolment.courseName}`.toLowerCase();
        return stringToSearch.includes(searchValue.toLowerCase());
      });
      setFilteredData(d);
    } else {
      setFilteredData(data);
    }
  }, [data, searchValue]);

  return (
    <React.Fragment>
      <Helmet title="Enrolments" />
      <Typography variant="h3" gutterBottom display="inline">
        Enrolments
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Enrolments</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {filteredData ? (
        <PrimaryTable
          tableTitle="Enrolments"
          data={filteredData}
          headCells={enrolmentsHeadCells(true)}
          onSearch={doSearchEnrolments}
          ActionComponent={
            <Button
              component={NavLink}
              to="/enrol"
              variant="contained"
              color="primary"
            >
              Add New
            </Button>
          }
        />
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

export default Enrolments;
