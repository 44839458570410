import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import { NavLink, useHistory } from "react-router-dom";

import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Grid,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import Loader from "../../../components/Loader";
import { AdminApi } from "../../../backendIntegration/AdminApi";
import { Company } from "../../../models/Company";
import Stats from "./Stats";
import { green } from "@material-ui/core/colors";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Companies() {
  const [data, setData] = useState<Company[]>();
  const history = useHistory();

  const adminApi = useMemo(() => new AdminApi(), []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await adminApi.getCompanies();
      setData(response);
    };
    fetchData();
  }, [adminApi]);

  return (
    <React.Fragment>
      <Helmet title="Companies" />
      <Typography variant="h3" gutterBottom display="inline">
        Companies
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Companies</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {data ? (
        <Grid container spacing={6}>
          {data.map((company) => (
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                key={company.id}
                title={company.name}
                amount={`${company.totalEur} EUR`}
                chip={`${company.totalEurPaid} EUR`}
                managers={company.managerEmails}
                managerColor={green[500]}
                onClick={() =>
                  history.push(`/admin/companies/company/${company.id}`)
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

export default Companies;
