import { UserRole } from "../../backendIntegration/AuthApi";
import * as types from "../../constants";
import { removeJwtFromLocalStorage } from "../../utils/localStorageHelper";
import { InvalidException } from "../actions/InvalidException";

export type UserType = {
  // id?: string;
  // email?: string;
  // name?: string;
  jwt?: string;
  role?: UserRole;
};

export type AuthType = {
  user?: UserType | undefined;
};

export default function reducer(
  state = {},
  actions: UserType & { type: string }
): AuthType {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      if (!actions.jwt) {
        throw InvalidException("Cannot save empty JWT token.");
      }
      return {
        ...state,
        user: {
          jwt: actions.jwt,
          role: actions.role,
        },
      };

    case types.AUTH_SIGN_OUT:
      removeJwtFromLocalStorage();
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
