import * as types from "../../constants";
import { AppDispatchType } from "../store";
import { SignInType, ResetPasswordType } from "../../types/auth";
import { AuthApi, LoginReturnStatus } from "../../backendIntegration/AuthApi";
import { removeJwtFromLocalStorage } from "../../utils/localStorageHelper";
import { DecodeJwt } from "../../utils/jwt";
import { InvalidException } from "./InvalidException";

export function signIn(credentials: SignInType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    const authApi = new AuthApi();

    return authApi
      .login(credentials.email, credentials.password)
      .then((response) => {
        if (response.status === LoginReturnStatus.Success) {
          dispatch({
            type: types.AUTH_SIGN_IN_SUCCESS,
            jwt: response.jwtToken,
            role: DecodeJwt(response.jwtToken).role,
          });
        } else {
          dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
          throw InvalidException("Invalid email or password");
        }
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });

    // return authSignIn(credentials)
    //   .then((response: any) => {
    //     dispatch({
    //       type: types.AUTH_SIGN_IN_SUCCESS,
    //       id: response.id,
    //       email: response.email,
    //       name: response.name,
    //     });
    //   })
    //   .catch((error) => {
    //     dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
    //     throw error;
    //   });
  };
}

export function signOut() {
  return async (dispatch: AppDispatchType) => {
    removeJwtFromLocalStorage();
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function requestResetPassword(credentials: ResetPasswordType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });
    const authApi = new AuthApi();
    return authApi
      .requestResetPasswordEmail(credentials)
      .then(() => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: credentials.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function resetPassword(userId: number, password: string, token: string) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });
    const authApi = new AuthApi();
    return authApi
      .resetPassword(userId, token, password)
      .then(() => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw InvalidException("Invalid token.");
      });
  };
}

export function reauthenticate(jwtToken: string) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    const role = DecodeJwt(jwtToken).role;
    dispatch({
      type: types.AUTH_SIGN_IN_SUCCESS,
      jwt: jwtToken,
      role: role,
    });
  };
}
