// import { HeadCell } from "../components/Tables/PrimaryTable";
import { ProtectedHttpClient } from "./ProtectedHttpClient";
import { Company, CompanyServerModel } from "../models/Company";

export interface CreateCourseModel {
  name: string;
  description: string;
  priceEur: number;
  externalId: string;
  category: string;
}

export class AdminApi extends ProtectedHttpClient {
  public getCompanies = async () => {
    const response = await this.instance.post<CompanyServerModel>("/graphql", {
      query: `
      {
        companies {
            id
            name
                students {
                id
                enrollments {
                id
                paidAtUTC
                courseInfo {
                    priceEur
                }
                }
            }
                managers {
                id
                userName
            }
            }
        }
      `,
    });
    const companies: Company[] = response.data.companies.map(
      (company): Company => {
        const totalEur = company.students.reduce(
          (prevValue, currValue) =>
            prevValue +
            currValue.enrollments.reduce(
              (prevValue, currValue) =>
                prevValue + currValue.courseInfo.priceEur,
              0
            ),
          0
        );

        const paidEur = company.students.reduce(
          (prevValue, currValue) =>
            prevValue +
            currValue.enrollments.reduce(
              (prevValue, currValue) =>
                prevValue +
                (currValue.paidAtUTC === null
                  ? 0
                  : currValue.courseInfo.priceEur),
              0
            ),
          0
        );

        return {
          id: company.id,
          name: company.name,
          numberOfStudents: company.students.length,
          numberOfManagers: company.managers.length,
          totalEur: totalEur,
          totalEurPaid: paidEur,
          managerEmails: company.managers.map((manager) => manager.userName),
        };
      }
    );
    return companies;
  };

  public createCompany = async (name: string) => {
    const body = JSON.stringify({
      name,
    });
    return this.instance.post<boolean>(
      "/api/administrator/create-company",
      body
    );
  };

  public createCourse = async (course: CreateCourseModel) => {
    const body = JSON.stringify(course);
    return this.instance.post<boolean>(
      "/api/administrator/create-course",
      body
    );
  };

  public getCompany = async (companyId: number) => {
    const response = await this.instance.post<CompanyServerModel>("/graphql", {
      query: `
      {
        companies(where:{id:{eq:${companyId}}}) {
            id
            name
            students {
              id
              enrollments {
                id
                createdAtUTC
                paidAtUTC
                student {
                  userName
                  firstName
                  surname
                }
                courseInfo {
                  name
                  priceEur                      
                }        
              }
            }
            managers {
              id
              userName
            }
            
        }
      }
      `,
    });
    return response.data.companies[0];
  };

  public markAsPaid = async (enrolmentIds: number[]) => {
    const body = JSON.stringify(enrolmentIds);
    return this.instance.post<boolean>("/api/enrollments/pay", body);
  };

  public createManager = async (email: string, companyId: number) => {
    const body = JSON.stringify({
      username: email,
      companyId,
    });
    return this.instance.post<boolean>("/api/users/create-manager", body);
  };
}
