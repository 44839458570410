import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Button,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {
  CreateStudentModel,
  StudentsApi,
  studentsHeadCells,
} from "../../backendIntegration/StudentsApi";
import { Student } from "../../models/Student";
import PrimaryTable from "../../components/Tables/PrimaryTable";
import Loader from "../../components/Loader";
import { CreateUserDialog } from "./components/CreateUserDialog";
import { useCallback } from "react";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Students() {
  const [data, setData] = useState<Student[]>();
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

  const studentsApi = useMemo(() => new StudentsApi(), []);

  const fetchData = useCallback(async () => {
    const response = await studentsApi.getStudents();
    setData(response);
  }, [studentsApi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openDialog = () => {
    setOpenCreateDialog(true);
  };
  const closeDialog = () => {
    setOpenCreateDialog(false);
  };

  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Student[]>();

  const doSearchStudents = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (data && searchValue) {
      const d = data.filter((student: Student) => {
        const stringToSearch = `${student.email} ${student.firstName} ${student.surname}`.toLowerCase();
        return stringToSearch.includes(searchValue.toLowerCase());
      });
      setFilteredData(d);
    } else {
      setFilteredData(data);
    }
  }, [data, searchValue]);

  const createUser = async (values: CreateStudentModel) => {
    const response = await studentsApi.createStudent(values);
    if (response) {
      // refetch data after adding new user
      await fetchData();
    }
    return response;
  };

  return (
    <React.Fragment>
      <Helmet title="Users" />
      <Typography variant="h3" gutterBottom display="inline">
        Users
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Users</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {filteredData ? (
        <PrimaryTable
          tableTitle="Users"
          data={filteredData}
          headCells={studentsHeadCells}
          ActionComponent={
            <Button onClick={openDialog} variant="contained" color="primary">
              Add New
            </Button>
          }
          onSearch={doSearchStudents}
        />
      ) : (
        <Loader />
      )}
      <CreateUserDialog
        open={openCreateDialog}
        onClose={closeDialog}
        onSubmit={createUser}
      />
    </React.Fragment>
  );
}

export default Students;
