/* eslint-disable import/first */
import React from "react";
import { List, Monitor, Users, UserPlus } from "react-feather";
import { ViewModule } from "@material-ui/icons";

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
// import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import Dashboard from "../pages/dashboard";
import Enrolments from "../pages/enrolments";
import Enrol from "../pages/enrol/Index";
import Students from "../pages/students/Index";
import Courses from "../pages/courses/Index";

// Admin routrs
import Companies from "../pages/admin/companies/Index";
import Company from "../pages/admin/companies/company/Index";

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Our pages
const dashboardRoutes = {
  id: "Dashboard",
  path: "/",
  icon: <Monitor />,
  component: Dashboard,
  children: null,
  guard: AuthGuard,
};
const enrolmentsRoutes = {
  id: "Enrolments",
  path: "/enrolments",
  icon: <List />,
  component: Enrolments,
  children: null,
  guard: AuthGuard,
};

const enrolStudents = {
  id: "Enrol Users",
  path: "/enrol",
  icon: <UserPlus />,
  component: Enrol,
  children: null,
  guard: AuthGuard,
};

const students = {
  id: "Users",
  path: "/users",
  icon: <Users />,
  component: Students,
  children: null,
  guard: AuthGuard,
};

const courses = {
  id: "Courses",
  path: "/courses",
  icon: <ViewModule />,
  component: Courses,
  children: null,
  guard: AuthGuard,
};

const admin = {
  id: "Admin",
  path: "/admin",
  icon: <ViewModule />,
  guard: AuthGuard,
  children: [
    {
      path: "/admin/companies",
      name: "Companies",
      component: Companies,
    },
    {
      path: "/admin/companies/company/:id",
      name: "Company",
      component: Company,
    },
  ],
  component: null,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [
  dashboardRoutes,
  enrolmentsRoutes,
  enrolStudents,
  students,
  courses,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  authRoutes,
  dashboardRoutes,
  enrolmentsRoutes,
  enrolStudents,
  students,
  courses,
];

export const adminRoutes = [admin];
