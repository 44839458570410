import { Student, StudentServerModel } from "../models/Student";
import { HeadCell } from "../components/Tables/PrimaryTable";
import { ProtectedHttpClient } from "./ProtectedHttpClient";

export type CreateStudentModel = {
  firstName: string;
  surname: string;
  email: string;
};

export class StudentsApi extends ProtectedHttpClient {
  public getStudents = async (): Promise<Student[]> => {
    const response = await this.instance.post<StudentServerModel>("/graphql", {
      query: `
      {
        students {
            id
            firstName
            surname
            userName      

        }
      }`,
    });
    const students = response.data.students.map(
      ({ firstName, surname, id, userName }) => {
        const student: Student = {
          id,
          firstName,
          surname,
          email: userName,
        };
        return student;
      }
    );
    return students;
  };

  public createStudent = (student: CreateStudentModel) => {
    const body = JSON.stringify({
      firstName: student.firstName,
      surname: student.surname,
      email: student.email,
    });
    return this.instance.post<boolean>("/api/users/create-student", body);
  };
}

export const studentsHeadCells: Array<HeadCell> = [
  {
    id: "id",
    format: "number",
    disablePadding: false,
    label: "Id",
    align: "left",
    hiddenColumn: true,
  },
  {
    id: "firstName",
    format: "string",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "surname",
    format: "string",
    disablePadding: false,
    label: "Surname",
  },
  { id: "email", format: "string", disablePadding: false, label: "Email" },
];
