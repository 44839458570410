export const getJwtFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const setJwtToLocalStorage = (jwtToken: string) => {
  localStorage.setItem("token", jwtToken);
};

export const removeJwtFromLocalStorage = () => {
  localStorage.removeItem("token");
};
