import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Card as MuiCard,
  CardContent,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import PrimaryTable from "../../components/Tables/PrimaryTable";
import Loader from "../../components/Loader";
import {
  CoursesApi,
  coursesHeadCells,
} from "../../backendIntegration/CoursesApi";
import { Course } from "../../models/Course";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Courses() {
  const [courseData, setCourseData] = useState<Course[]>();

  const coursesApi = useMemo(() => new CoursesApi(), []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await coursesApi.getCourses();
      setCourseData(response);
    };
    fetchData();
  }, [coursesApi]);

  const [searchCoursesValue, setSearchCoursesValue] = useState<string>("");
  const [filteredCoursesData, setFilteredCoursesData] = useState<Course[]>();

  const doSearch = (value: string) => {
    setSearchCoursesValue(value);
  };

  useEffect(() => {
    if (courseData && searchCoursesValue) {
      const d = courseData.filter((course: Course) => {
        const stringToSearch = `${course.name} ${course.category} ${course.description}`.toLowerCase();
        return stringToSearch.includes(searchCoursesValue.toLowerCase());
      });
      setFilteredCoursesData(d);
    } else {
      setFilteredCoursesData(courseData);
    }
  }, [courseData, searchCoursesValue]);

  return (
    <React.Fragment>
      <Helmet title="Course catalogue" />
      <Typography variant="h3" gutterBottom display="inline">
        Course catalogue
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Courses</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Card title="Preview courses" my={6}>
        <CardContent>
          <Typography variant="h6" mb={1}>
            Request preview
          </Typography>
          <Typography variant="body1">
            If you want to preview the courses before enrolling users, please
            contact us at <Link href="mailto:info@ael.aero">info@ael.aero</Link>{" "}
            to get a demo.
          </Typography>
        </CardContent>
      </Card>

      {filteredCoursesData ? (
        <PrimaryTable
          tableTitle="Course catalogue"
          data={filteredCoursesData}
          headCells={coursesHeadCells}
          onSearch={doSearch}
          showAll
        />
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

export default Courses;
