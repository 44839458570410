import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  CardActionArea,
  CardMedia as MuiCardMedia,
  SvgIcon as MuiSvgIcon,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { UserRole } from "../../backendIntegration/AuthApi";
import AdminDashboard from "./AdminDashboard";
import { UserPlus } from "react-feather";
import { ListAlt, AssignmentInd, History } from "@material-ui/icons";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const CardMedia = styled(MuiCardMedia)`
  height: 140px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const SvgIcon = styled(MuiSvgIcon)`
  color: #00245d;
  height: 120px;
  width: 120px;
  margin-top: 10px;
  padding: 20px;
`;

function Default() {
  const auth = useSelector((state: AppStateType) => state.authReducer);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome!
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>{/* <Actions /> */}</Grid>
      </Grid>

      <Divider my={6} />

      {auth.user ? (
        auth.user.role && auth.user.role === UserRole.admin ? (
          <AdminDashboard />
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card mb={6}>
                <CardActionArea component={Link} to="/users">
                  <CardMedia title="Manage users">
                    <SvgIcon>
                      <UserPlus />
                    </SvgIcon>
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Add users
                    </Typography>
                    <Typography component="p">
                      Add new and see existing users.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card mb={6}>
                <CardActionArea component={Link} to="/enrol">
                  <CardMedia title="Enrol users">
                    <SvgIcon>
                      <AssignmentInd />
                    </SvgIcon>
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Enrol users
                    </Typography>
                    <Typography component="p">
                      Enrol your users to courses.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card mb={6}>
                <CardActionArea component={Link} to="/enrolments">
                  <CardMedia title="Enrolments">
                    <SvgIcon>
                      <History />
                    </SvgIcon>
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Existing enrolments
                    </Typography>
                    <Typography component="p">
                      See your enrolment history.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card mb={6}>
                <CardActionArea component={Link} to="/courses">
                  <CardMedia title="Course catalogue">
                    <SvgIcon>
                      <ListAlt />
                    </SvgIcon>
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Course catalogue
                    </Typography>
                    <Typography component="p">
                      See available courses and prices.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )
      ) : (
        <>Role unknown</>
      )}
    </React.Fragment>
  );
}

export default Default;
