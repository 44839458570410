import { ResetPasswordType } from "../types/auth";
import { setJwtToLocalStorage } from "../utils/localStorageHelper";
import { HttpClient } from "./HttpClient";

export enum LoginReturnStatus {
  Success = "Success",
  Failed = "Failed",
  EmailNotConfirmed = "EmailNotConfirmed",
}

export enum UserRole {
  admin = "1",
  manager = "2",
}

type LoginReturnValue = {
  status: LoginReturnStatus;
};

type LoginSuccess = LoginReturnValue & {
  status: LoginReturnStatus.Success;
  jwtToken: string;
  userType: UserRole;
};

type LoginFailed = LoginReturnValue & {
  status: LoginReturnStatus.Failed | LoginReturnStatus.EmailNotConfirmed;
  reason: string;
};

type LoginSuccessResponse = {
  jwtToken: string;
  userType: UserRole;
};

type ResetPasswordResponse = {
  success: boolean;
  errors: string[];
};

export class AuthApi extends HttpClient {
  // login user
  public login = async (
    email: string,
    password: string
  ): Promise<LoginSuccess | LoginFailed> => {
    const body = JSON.stringify({
      userName: email,
      password,
    });

    const response = await this.instance.post<LoginSuccessResponse>(
      "/api/auth/login",
      body
    );

    if (response.jwtToken) {
      setJwtToLocalStorage(response.jwtToken);
      return {
        status: LoginReturnStatus.Success,
        jwtToken: response.jwtToken,
        userType: response.userType,
      };
    }
    return {
      status: LoginReturnStatus.Failed,
      reason: "Email does not exist or password does not match the email.",
    };
  };

  public requestResetPasswordEmail(credentials: ResetPasswordType) {
    const body = JSON.stringify({
      email: credentials.email,
    });
    return new Promise((resolve, reject) => {
      this.instance
        .post("/api/auth/send-reset-password-email", body)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public resetPassword(userId: number, token: string, password: string) {
    const body = JSON.stringify({
      userId,
      password,
      token,
    });
    return new Promise((resolve, reject) => {
      this.instance
        .post<ResetPasswordResponse>("/api/auth/reset-password", body)
        .then((response: any) => {
          console.log(response);
          if (response.success) {
            resolve(true);
          } else {
            reject(response.errors[0]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
