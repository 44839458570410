import * as React from "react";
import styled, { withTheme } from "styled-components/macro";

import { Grid, AppBar as MuiAppBar, Toolbar, Link } from "@material-ui/core";

import UserDropdown from "./UserDropdown";
import { ReactComponent as Logo } from "../vendor/logo.svg";
import { NavLink } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 100%;
  max-height: 34px;
  margin-top: 10px;
`;

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <Link component={NavLink} to="/">
              <BrandIcon />
            </Link>
          </Grid>
          <Grid item xs />
          <Grid item>
            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default withTheme(AppBarComponent);
