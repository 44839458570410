import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import { NavLink, useParams } from "react-router-dom";

import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import Loader from "../../../../components/Loader";
import { AdminApi } from "../../../../backendIntegration/AdminApi";
import { FullCompany } from "../../../../models/Company";
import PrimaryTable from "../../../../components/Tables/PrimaryTable";
import { enrolmentsHeadCells } from "../../../../backendIntegration/EnrolmentsApi";
import { Enrolment } from "../../../../models/Enrolment";
import { ButtonWithLoader } from "../../../../components/Buttons/ButtonWithLoader";
import { useCallback } from "react";
import { CreateCompanyManagerDialog } from "./CreateCompanyManagerDialog";
import { format } from "date-fns";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);

function Company() {
  const { id } = useParams<{ id: string }>();
  const adminApi = useMemo(() => new AdminApi(), []);
  const companyId = parseInt(id);
  const [companyData, setCompanyData] = useState<FullCompany>();
  const [selectedEnrolments, setSelectedEnrolments] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openCreateManagerDialog, setOpenCreateManagerDialog] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Enrolment[]>();
  const [allEnrolments, setAllEnrolments] = useState<Enrolment[]>();

  const fetchData = useCallback(async () => {
    const response = await adminApi.getCompany(companyId);
    setCompanyData(response);
  }, [adminApi, companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createManager = useCallback(
    async (email: string) => {
      await adminApi.createManager(email, companyId);
      return true;
    },
    [adminApi, companyId]
  );

  useEffect(() => {
    if (companyData) {
      const emptyEnrollment: Enrolment[] = [];
      const enrolments = companyData.students.reduce((prevValue, currValue) => {
        return prevValue.concat(
          currValue.enrollments.map((enrollment) => {
            const enrol: Enrolment = {
              id: enrollment.id,
              firstName: enrollment.student.firstName,
              surname: enrollment.student.surname,
              email: enrollment.student.userName,
              enrolmentDate: new Date(enrollment.createdAtUTC).getTime(),
              courseName: enrollment.courseInfo.name,
              priceEur: enrollment.courseInfo.priceEur,
              paid: enrollment.paidAtUTC
                ? format(new Date(enrollment.paidAtUTC), "yyyy-MM-dd HH:mm z")
                : "",
              isRowDisabled: enrollment.paidAtUTC !== null,
            };
            return enrol;
          })
        );
      }, emptyEnrollment);
      setAllEnrolments(enrolments);
    }
  }, [companyData]);

  useEffect(() => {
    if (allEnrolments) {
      if (allEnrolments && searchValue) {
        const d = allEnrolments.filter((enrolment: Enrolment) => {
          const stringToSearch = `${enrolment.email} ${enrolment.firstName} ${enrolment.surname} ${enrolment.courseName}`.toLowerCase();
          return stringToSearch.includes(searchValue.toLowerCase());
        });
        setFilteredData(d);
      } else {
        setFilteredData(allEnrolments);
      }
    }
  }, [allEnrolments, searchValue]);

  if (!companyData) {
    return <Loader />;
  }

  const markEnrolmentsAsPaid = async () => {
    setIsSubmitting(true);
    if (selectedEnrolments.length > 0) {
      await adminApi.markAsPaid(selectedEnrolments);
      await fetchData();
      setSelectedEnrolments([]);
    }
    setIsSubmitting(false);
  };

  const doSearchEnrolments = (value: string) => {
    setSearchValue(value);
  };

  return (
    <React.Fragment>
      <Helmet title="Company" />
      <Typography variant="h3" gutterBottom display="inline">
        {companyData.name}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/admin/companies">
          Companies
        </Link>
        <Typography>{companyData.name}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {filteredData ? (
        <PrimaryTable
          tableTitle="Enrolments"
          data={filteredData}
          headCells={enrolmentsHeadCells()}
          onSearch={doSearchEnrolments}
          selected={selectedEnrolments}
          setSelected={setSelectedEnrolments}
          ActionComponent={
            <>
              <Button
                mr={3}
                variant="contained"
                color="primary"
                onClick={() => setOpenCreateManagerDialog(true)}
              >
                Create Manager
              </Button>
              <ButtonWithLoader
                variant="contained"
                color="primary"
                onClick={markEnrolmentsAsPaid}
                disabled={selectedEnrolments.length === 0}
                loading={isSubmitting}
              >
                Invoice selected
              </ButtonWithLoader>
            </>
          }
        />
      ) : (
        <Loader />
      )}
      <CreateCompanyManagerDialog
        open={openCreateManagerDialog}
        onSubmit={(value) => createManager(value.email)}
        onClose={() => setOpenCreateManagerDialog(false)}
      />
    </React.Fragment>
  );
}

export default Company;
