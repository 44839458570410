import { Enrolment, EnrolmentServerModel } from "../models/Enrolment";
import { HeadCell } from "../components/Tables/PrimaryTable";
import { ProtectedHttpClient } from "./ProtectedHttpClient";
import { AxiosResponse } from "axios";

interface CreateEnrolmentModel {
  studentId: number;
  courseId: number;
}

export class EnrolmentsApi extends ProtectedHttpClient {
  public getEnrolments = async (): Promise<Enrolment[]> => {
    const response = await this.instance.post<EnrolmentServerModel>(
      "/graphql",
      {
        query: `
      {
        companyEnrollments {
          id
          createdAtUTC
          paidAtUTC
          courseInfo {
            name       
            priceEur
          }
          student {
            userName
            firstName
            surname
          }
        }  
      }
      `,
      }
    );

    const enrolments: Enrolment[] = response.data.companyEnrollments.map(
      ({ id, createdAtUTC, paidAtUTC, student, courseInfo }) => {
        const enrolment: Enrolment = {
          id,
          firstName: student.firstName,
          surname: student.surname,
          email: student.userName,
          enrolmentDate: new Date(createdAtUTC).getTime(),
          courseName: courseInfo.name,
          priceEur: courseInfo.priceEur,
          paid: paidAtUTC === null ? "Not paid" : "Paid",
        };
        return enrolment;
      }
    );
    return enrolments;
  };

  public createEnrolment = async (
    studentIds: number[],
    courseIds: number[],
    emails: string
  ) => {
    let enrollments: CreateEnrolmentModel[] = [];
    studentIds.forEach((studentId) => {
      courseIds.forEach((courseId) => {
        enrollments.push({ studentId, courseId });
      });
    });
    const body = JSON.stringify({
      enrollments,
      notificationEmailAddresses: emails.split(","),
    });
    return this.instance
      .post<AxiosResponse>("/api/enrollments/add-new", body)
      .then((response) => {
        return true;
      })
      .catch((response) => {
        return false;
      });
  };
}

export const enrolmentsHeadCells = (hidePaid?: boolean): Array<HeadCell> => [
  {
    id: "id",
    format: "number",
    disablePadding: false,
    label: "Id",
    align: "left",
    hiddenColumn: true,
  },
  {
    id: "firstName",
    format: "string",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "surname",
    format: "string",
    disablePadding: false,
    label: "Surname",
  },
  { id: "email", format: "string", disablePadding: false, label: "Email" },
  {
    id: "enrolmentDate",
    format: "date",
    disablePadding: false,
    label: "Enrolment Date",
    defaultOrderBy: true,
  },
  {
    id: "courseName",
    format: "string",
    disablePadding: false,
    label: "Course",
  },
  {
    id: "priceEur",
    format: "number",
    disablePadding: false,
    label: "Price in Eur",
  },
  {
    id: "paid",
    format: "string",
    disablePadding: false,
    label: "Invoiced at",
    hiddenColumn: hidePaid,
  },
];
