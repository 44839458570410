import React, { useCallback } from "react";
import styled from "styled-components/macro";

import {
  Checkbox,
  InputBase,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar as MuiToolbar,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { format } from "date-fns";
import { Search as SearchIcon } from "@material-ui/icons";
import { darken } from "polished";
import { useState } from "react";
import { useEffect } from "react";

const Paper = styled(MuiPaper)(spacing);
const Toolbar = styled(MuiToolbar)(spacing);
const TableText = styled.span<{ $noWrap?: boolean }>`
  ${(props) =>
    props.$noWrap
      ? `
      display:block;
      max-width:250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `
      : ``};
`;

export type RowType = {
  [key: string]: any;
  isRowDisabled?: boolean;
};

export type HeadCell = {
  id: string;
  format: "string" | "date" | "number";
  disablePadding: boolean;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  hiddenColumn?: boolean;
  noWrap?: boolean;
  textAfter?: string;
  defaultOrderBy?: boolean;
};

// function createData(
//   name: string,
//   calories: number,
//   fat: number,
//   carbs: number,
//   protein: number
// ) {
//   return { name, calories, fat, carbs, protein };
// }

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
    : (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<RowType>,
  comparator: (a: RowType, b: RowType) => number
) {
  const stabilizedThis = array.map((el: RowType, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  position: relative;
  width: 100%;
  max-width: 450px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

type PrimaryTableHeadPropsType = {
  headCells: HeadCell[];
  numSelected: number;
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  checkboxEnabled?: boolean;
  onSelectAllClick: (e: any) => void;
  onRequestSort: (e: any, property: string) => void;
};
const PrimaryTableHead: React.FC<PrimaryTableHeadPropsType> = ({
  headCells,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  checkboxEnabled,
  onRequestSort,
}) => {
  const createSortHandler = useCallback(
    (property: string) => (event: any) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  useEffect(() => {
    const defaultOrderBy = headCells.find(
      (headCell) => headCell.defaultOrderBy
    );
    if (defaultOrderBy) {
      createSortHandler(defaultOrderBy.id);
    }
  }, [createSortHandler, headCells]);

  return (
    <TableHead>
      <TableRow>
        {checkboxEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => {
          if (headCell.hiddenColumn) {
            return null;
          }
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

type PrimaryTableToolbarPropsType = {
  numSelected: number;
  tableTitle: string;
  tableDescription?: string;
  ActionComponent: React.ReactNode;
  onSearch?: (value: string) => void;
};
const PrimaryTableToolbar = (props: PrimaryTableToolbarPropsType) => {
  const {
    numSelected,
    tableTitle,
    tableDescription,
    ActionComponent,
    onSearch,
  } = props;

  return (
    <Toolbar p={4}>
      <Box display="flex" flex="1" flexDirection="column">
        <Box display="flex" flex="1" justifyContent="space-between">
          <div>
            <Typography variant="h6" id="tableTitle">
              {tableTitle}
            </Typography>
            {tableDescription && (
              <Typography
                variant="caption"
                id="tableDescription"
                color="textSecondary"
              >
                {tableDescription}
              </Typography>
            )}
          </div>
          <div>
            {/* {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )} */}
            {ActionComponent}
          </div>
        </Box>
        <Box display="flex" flex="1" justifyContent="space-between">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              placeholder="Search..."
              onChange={onSearch ? (e) => onSearch(e.target.value) : undefined}
            />
          </Search>
          <div>
            {numSelected > 0 && (
              <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
            )}
          </div>
        </Box>
      </Box>
    </Toolbar>
  );
};

interface PrimaryTableProps {
  headCells: HeadCell[];
  data: RowType[];
  tableTitle: string;
  tableDescription?: string;
  ActionComponent?: React.ReactNode;
  setSelected?: (selected: number[]) => void;
  onSearch?: (value: string) => void;
  selected?: number[];
  hideDenseToggle?: boolean;
  showAll?: boolean;
}
export const PrimaryTable: React.FC<PrimaryTableProps> = ({
  data,
  headCells,
  tableTitle,
  tableDescription,
  ActionComponent,
  setSelected,
  onSearch,
  selected,
  hideDenseToggle,
  showAll,
}) => {
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(showAll ? data.length : 10);

  // Reset to first page on search
  useEffect(() => {
    setPage(0);
  }, [data]);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelected) {
      if (event.target.checked) {
        const newSelecteds = data
          .filter((n) => n.paid !== "Paid")
          .map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ) => {
    if (selected !== undefined && setSelected !== undefined) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) =>
    selected ? selected.indexOf(id) !== -1 : false;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <PrimaryTableToolbar
          numSelected={selected ? selected.length : 0}
          tableTitle={tableTitle}
          tableDescription={tableDescription}
          ActionComponent={ActionComponent}
          onSearch={onSearch}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="primary table"
          >
            <PrimaryTableHead
              headCells={headCells}
              numSelected={selected ? selected.length : 0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.filter((n) => n.paid !== "Paid").length}
              checkboxEnabled={setSelected !== undefined ? true : false}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isCheckboxDisabled = row.isRowDisabled; // TODO
                  const labelId = `primary-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={
                        isCheckboxDisabled
                          ? undefined
                          : (event) => handleClick(event, row.id)
                      }
                      role={setSelected ? "checkbox" : ""}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {setSelected && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={isCheckboxDisabled}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                      )}
                      {Object.entries(row).map(([key, value], index) => {
                        if (key === "isRowDisabled") {
                          return null;
                        }
                        const headCell = headCells[index];
                        const stringValue =
                          headCell.format === "date"
                            ? format(
                                new Date(row.enrolmentDate),
                                "dd-MM-yyyy HH:mm z"
                              )
                            : value.toString();
                        if (headCell.hiddenColumn) {
                          return null;
                        }
                        return (
                          <TableCell align={headCell.align} key={index}>
                            <TableText
                              $noWrap={headCell.noWrap}
                              title={stringValue}
                            >
                              {stringValue}
                              {headCell.textAfter}
                            </TableText>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showAll !== true && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {hideDenseToggle === true ? null : (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )}
    </div>
  );
};

export default PrimaryTable;
