import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField as MuiTextField,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing, SpacingProps } from "@material-ui/system";
import { CreateStudentModel } from "../../../backendIntegration/StudentsApi";
import { Close } from "@material-ui/icons";

const Alert = styled(MuiAlert)(spacing);
const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const initialValues: CreateStudentModel = {
  firstName: "",
  surname: "",
  email: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
});

interface CreateUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateStudentModel) => Promise<boolean>;
}
export const CreateUserDialog: React.FC<CreateUserDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [status, setStatus] = useState<boolean | null>(null);
  const hideStatusAfterWhile = useCallback(() => {
    const timer = setTimeout(() => {
      setStatus(null);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = async (
    values: CreateStudentModel,
    { resetForm, setErrors, setSubmitting }: any
  ) => {
    try {
      setStatus(null);
      const isCreatedSuccessfully = await onSubmit(values);
      setSubmitting(false);
      if (isCreatedSuccessfully) {
        resetForm();
        setStatus(true);
        hideStatusAfterWhile();
      } else {
        setErrors({ submit: "User was not created." });
        setStatus(false);
        hideStatusAfterWhile();
      }
    } catch (error) {
      setStatus(false);
      hideStatusAfterWhile();
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Create new user
              {!isSubmitting && (
                <CloseIconButton aria-label="Close" onClick={onClose}>
                  <Close fontSize="small" />
                </CloseIconButton>
              )}
            </DialogTitle>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <DialogContent>
                {status === true ? (
                  <Alert severity="success" my={3}>
                    The user has been created successfully!
                  </Alert>
                ) : status === false ? (
                  <Alert severity="error" my={3}>
                    An error has occured, please try again.
                  </Alert>
                ) : null}
                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                />
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="surname"
                      label="Surname"
                      value={values.surname}
                      error={Boolean(touched.surname && errors.surname)}
                      fullWidth
                      helperText={touched.surname && errors.surname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            )}
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
