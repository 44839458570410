import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Card as MuiCard,
  Breadcrumbs as MuiBreadcrumbs,
  Box as MuiBox,
  Link,
  Grid,
  TextField,
  Dialog,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import PrimaryTable from "../../components/Tables/PrimaryTable";
import Loader from "../../components/Loader";
import {
  StudentsApi,
  studentsHeadCells,
} from "../../backendIntegration/StudentsApi";
import { Student } from "../../models/Student";
import {
  CoursesApi,
  coursesHeadCells,
} from "../../backendIntegration/CoursesApi";
import { Course } from "../../models/Course";
import { ButtonWithLoader } from "../../components/Buttons/ButtonWithLoader";
import { EnrolmentsApi } from "../../backendIntegration/EnrolmentsApi";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Box = styled(MuiBox)(spacing);

const isEmailSchema = Yup.string().email();
const validationSchema = Yup.object().shape({
  emails: Yup.string()
    .required("'Send copy of certificates to' cannot be empty.")
    .test({
      name: "emails",
      test: function (value: any) {
        const firstInvalidEmail = value
          .split(",")
          .map((email: string) => email.trim())
          .filter((v: string) => v.length > 0)
          .find((v: string) => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: `The email address '${firstInvalidEmail}' is invalid/not in valid format.`,
            });
      },
    }),
  selectedUsers: Yup.array().min(1, "Select at least one user"),
  selectedCourses: Yup.array().min(1, "Select at least one course"),
});

function Enrol() {
  const [studentData, setStudentData] = useState<Student[]>();
  const [courseData, setCourseData] = useState<Course[]>();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [emails, setEmails] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [totalCost, setTotalCost] = useState<number>(0);

  const studentsApi = useMemo(() => new StudentsApi(), []);
  const coursesApi = useMemo(() => new CoursesApi(), []);
  const enrolmentApi = useMemo(() => new EnrolmentsApi(), []);

  // calculate total price
  useEffect(() => {
    if (courseData) {
      const courses = courseData.filter((course: Course) => {
        return selectedCourses.find((value) => {
          return value === course.id;
        });
      });
      const totalPrice = courses.reduce(
        (prevValue, currentValue) => prevValue + currentValue.priceEur,
        0
      );
      setTotalCost(totalPrice * selectedUsers.length);
    }
  }, [courseData, selectedCourses, selectedUsers]);

  const [searchStudentValue, setStudentSearchValue] = useState<string>("");
  const [filteredStudentData, setFilteredStudentData] = useState<Student[]>();

  const doSearchStudents = (value: string) => {
    setStudentSearchValue(value);
  };

  useEffect(() => {
    if (studentData && searchStudentValue) {
      const d = studentData.filter((student: Student) => {
        const stringToSearch = `${student.email} ${student.firstName} ${student.surname}`.toLowerCase();
        return stringToSearch.includes(searchStudentValue.toLowerCase());
      });
      setFilteredStudentData(d);
    } else {
      setFilteredStudentData(studentData);
    }
  }, [studentData, searchStudentValue]);

  const [searchCourseValue, setCourseSearchValue] = useState<string>("");
  const [filteredCourseData, setFilteredCourseData] = useState<Course[]>();

  const doSearchCourses = (value: string) => {
    setCourseSearchValue(value);
  };

  useEffect(() => {
    if (courseData && searchCourseValue) {
      const d = courseData.filter((course: Course) => {
        const stringToSearch = `${course.name} ${course.category} ${course.description}`.toLowerCase();
        return stringToSearch.includes(searchCourseValue.toLowerCase());
      });
      setFilteredCourseData(d);
    } else {
      setFilteredCourseData(courseData);
    }
  }, [courseData, searchCourseValue]);

  // Fetch students
  useEffect(() => {
    const fetchData = async () => {
      const response = await studentsApi.getStudents();
      setStudentData(response);
    };
    fetchData();
  }, [studentsApi]);

  // Fetch Courses
  useEffect(() => {
    const fetchData = async () => {
      const response = await coursesApi.getCourses();
      setCourseData(response);
    };
    fetchData();
  }, [coursesApi]);

  const onSubmit = async () => {
    setLoading(true);
    const isValid = await validate();
    if (isValid) {
      const isCreatedSuccessfully = await enrolmentApi.createEnrolment(
        selectedUsers,
        selectedCourses,
        emails
      );

      if (isCreatedSuccessfully) {
        setOpenSuccessDialog(true);
        resetForm();
      } else {
        setValidationErrors(["Error has occured, please try again."]);
      }
    }
    setLoading(false);
  };

  const resetForm = useCallback(() => {
    setSelectedCourses([]);
    setSelectedUsers([]);
    setEmails("");
    setCourseSearchValue("");
    setStudentSearchValue("");
  }, []);

  const validate = useCallback(async () => {
    validationSchema.cast({
      emails,
      selectedUsers,
      selectedCourses,
    });
    let isValid = false;
    await validationSchema
      .validate(
        {
          emails: emails,
          selectedUsers: selectedUsers,
          selectedCourses: selectedCourses,
        },
        { abortEarly: false }
      )
      .then(() => {
        setValidationErrors([]);
        isValid = true;
      })
      .catch(function (err) {
        console.log(err.errors);
        setValidationErrors(err.errors);
        isValid = false;
      });
    return isValid;
  }, [emails, selectedUsers, selectedCourses]);

  return (
    <React.Fragment>
      <Helmet title="Enrol users" />
      <Typography variant="h3" gutterBottom display="inline">
        Enrol users
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Enrol users</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {filteredCourseData && filteredStudentData ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Grid container spacing={6}>
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Card>
                  <>
                    <PrimaryTable
                      tableTitle="Users"
                      tableDescription="Select user(s) you want to enrol to course(s)."
                      data={filteredStudentData}
                      headCells={studentsHeadCells}
                      setSelected={setSelectedUsers}
                      selected={selectedUsers}
                      hideDenseToggle
                      onSearch={doSearchStudents}
                    />
                  </>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Card>
                  <>
                    <PrimaryTable
                      tableTitle="Courses"
                      tableDescription="Select course(s) you want user(s) to be enrolled in. Click “Enrol and Purchase” at the bottom to make the enrollment."
                      data={filteredCourseData}
                      headCells={coursesHeadCells}
                      setSelected={setSelectedCourses}
                      selected={selectedCourses}
                      hideDenseToggle
                      onSearch={doSearchCourses}
                    />
                  </>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card p={4}>
                  <TextField
                    color="secondary"
                    label="Send copy of certificates to"
                    required
                    helperText={
                      <Typography variant="body1">
                        Email addresses, to which a copy of the certificates
                        will be sent to. <br />
                        Comma separated format:
                        <i>example@ael.aero,example2@ael.aero</i>
                      </Typography>
                    }
                    value={emails}
                    onChange={(event) => {
                      setEmails(event.target.value);
                    }}
                  />

                  <Box mt={6}>
                    <Typography variant="body1">
                      Total: {totalCost} €
                    </Typography>
                  </Box>
                  <Box mt={6}>
                    <ButtonWithLoader
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={loading}
                    >
                      Enrol and Purchase
                    </ButtonWithLoader>
                    {validationErrors.map((errorMessage, index) => (
                      <Typography key={index} variant="body1" color="error">
                        {errorMessage}
                      </Typography>
                    ))}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            </>
          </Grid>
        </form>
      ) : (
        <Loader />
      )}
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
        color="primary"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            User(s) enrolled. You will receive an invoice at the end of the
            month. If you have any questions, contact us at{" "}
            <Link href="mailto:info@ael.aero">info@ael.aero</Link>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenSuccessDialog(false)}
            color="primary"
            variant="contained"
            size="large"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Enrol;
