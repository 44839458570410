import React, { ComponentType } from "react";
import {
  Button as IlptButton,
  ButtonProps,
  CircularProgress,
} from "@material-ui/core";

interface ButtonWithLoaderProps extends ButtonProps {
  loading?: boolean;
  ButtonComponent?: ComponentType<ButtonProps>;
}

export const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = ({
  loading,
  children,
  ButtonComponent = IlptButton,
  ...rest
}) => {
  if (loading) {
    return (
      <ButtonComponent {...rest} disabled>
        <CircularProgress size={24} />
      </ButtonComponent>
    );
  }
  return <ButtonComponent {...rest}>{children}</ButtonComponent>;
};
