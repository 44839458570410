import jwt_decode from "jwt-decode";
import { UserRole } from "../backendIntegration/AuthApi";

const FIVE_MINUTESS_IN_MS = 5 * 60 * 1000;

type DecodedJwtTokenSchema = {
  exp: number;
  role: UserRole;
};

export const IsValidJwt = (jwtToken: string) => {
  const decoded = DecodeJwt(jwtToken);
  return decoded.exp * 1000 > Date.now() + FIVE_MINUTESS_IN_MS;
};

export const DecodeJwt = (jwtToken: string) => {
  const decoded = jwt_decode<DecodedJwtTokenSchema>(jwtToken);
  return decoded;
};
