import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@material-ui/core";

import { rgba } from "polished";

import { spacing } from "@material-ui/system";
import { CardActionArea } from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Manager = styled(MuiTypography)<{
  percentagecolor: string;
  mb: number;
}>`
  span {
    display: inline-block;
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

type StatsPropsType = {
  title: string;
  amount: string;
  chip: string;
  managers: string[];
  managerColor: string;
  onClick: () => void;
};

const Stats: React.FC<StatsPropsType> = ({
  title,
  amount,
  chip,
  managers,
  managerColor: percentagecolor,
  onClick,
}) => {
  return (
    <Card mb={3}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography variant="h6" mb={4}>
            {title}
          </Typography>
          <Typography variant="caption">Total spent:</Typography>
          <Typography variant="h3" mb={3}>
            <Box fontWeight="fontWeightRegular">{amount}</Box>
          </Typography>
          Manager(s):{" "}
          <Manager
            variant="subtitle2"
            mb={4}
            color="textSecondary"
            percentagecolor={percentagecolor}
          >
            {managers.length > 0 ? (
              managers.map((manager) => <span key={manager}>{manager}</span>)
            ) : (
              <div>None</div>
            )}
          </Manager>
          <Chip label={`Paid: ${chip}`} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Stats;
