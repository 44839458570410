import { CourseServerModel } from "../models/Course";
import { HeadCell } from "../components/Tables/PrimaryTable";
import { ProtectedHttpClient } from "./ProtectedHttpClient";

export class CoursesApi extends ProtectedHttpClient {
  public getCourses = async () => {
    const response = await this.instance.post<CourseServerModel>("/graphql", {
      query: `
      {
        courses {
            id
            name
            category
            description
            priceEur  
          }
      }
      `,
    });
    return response.data.courses;
  };
}

export const coursesHeadCells: Array<HeadCell> = [
  {
    id: "id",
    format: "number",
    disablePadding: false,
    label: "Id",
    align: "left",
    hiddenColumn: true,
  },
  {
    id: "name",
    format: "string",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "category",
    format: "string",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "description",
    format: "string",
    disablePadding: false,
    label: "Intended for",
    noWrap: true,
  },
  {
    id: "priceEur",
    format: "number",
    disablePadding: false,
    label: "Price",
    textAfter: " €",
    align: "right",
  },
];
