import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { reauthenticate } from "../redux/actions/authActions";
import { AppStateType } from "../redux/reducers";
import { getJwtFromLocalStorage } from "../utils/localStorageHelper";
import { IsValidJwt } from "../utils/jwt";
import { signOut } from "../redux/actions/authActions";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const auth = useSelector((state: AppStateType) => state.authReducer);
  const dispatch = useDispatch();

  if (!auth.user) {
    // try to get token from localstorage
    const jwtToken = getJwtFromLocalStorage();

    if (!jwtToken) {
      return <Redirect to="/auth/sign-in" />;
    }
    if (!IsValidJwt(jwtToken)) {
      dispatch(signOut());
      return <Redirect to="/auth/sign-in" />;
    }

    dispatch(reauthenticate(jwtToken));

    return children;
  } else if (auth.user && auth.user.jwt && IsValidJwt(auth.user.jwt)) {
    return children;
  }
  return <Redirect to="/auth/sign-in" />;
}

export default AuthGuard;
