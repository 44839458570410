import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField as MuiTextField,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing, SpacingProps } from "@material-ui/system";
import { Close } from "@material-ui/icons";

const Alert = styled(MuiAlert)(spacing);
const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

interface CreateManagerModel {
  email: string;
}
const initialValues: CreateManagerModel = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

interface CreateCompanyManagerDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateManagerModel) => Promise<boolean>;
}
export const CreateCompanyManagerDialog: React.FC<CreateCompanyManagerDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [status, setStatus] = useState<boolean | null>(null);
  const hideStatusAfterWhile = useCallback(() => {
    const timer = setTimeout(() => {
      setStatus(null);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = async (
    values: CreateManagerModel,
    { resetForm, setErrors, setSubmitting }: any
  ) => {
    try {
      setStatus(null);
      const isCreatedSuccessfully = await onSubmit(values);
      setSubmitting(false);
      if (isCreatedSuccessfully) {
        resetForm();
        setStatus(true);
        hideStatusAfterWhile();
      } else {
        setErrors({ submit: "User was not created." });
        setStatus(false);
        hideStatusAfterWhile();
      }
    } catch (error) {
      setStatus(false);
      hideStatusAfterWhile();
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Create new manager
              {!isSubmitting && (
                <CloseIconButton aria-label="Close" onClick={onClose}>
                  <Close fontSize="small" />
                </CloseIconButton>
              )}
            </DialogTitle>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <DialogContent>
                {status === true ? (
                  <Alert severity="success" my={3}>
                    The user has been created successfully!
                  </Alert>
                ) : status === false ? (
                  <Alert severity="error" my={3}>
                    An error has occured, please try again.
                  </Alert>
                ) : null}
                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                />
                <DialogActions>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            )}
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
