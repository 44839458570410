import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  requestResetPassword,
  resetPassword,
} from "../../redux/actions/authActions";
import { NavLink } from "react-router-dom";

import {
  Box,
  Link,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Check } from "react-feather";
import { green } from "@material-ui/core/colors";
import { useQuery } from "../../hooks/useQuery";
import { ButtonWithLoader } from "../../components/Buttons/ButtonWithLoader";

const Alert = styled(MuiAlert)(spacing);
const TypographyWithSpace = styled(Typography)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const CheckIcon = styled(Check)`
  color: ${green[500]};
  width: 50px;
  height: 50px;
`;

function ResetPassword() {
  const dispatch = useDispatch();
  const query = useQuery();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const userId = query.get("userId");
  const token = query.get("token");
  if (userId && token) {
    return (
      <Wrapper>
        <Helmet title="Reset Password" />
        {showSuccessMessage ? (
          <>
            <Box display="flex" justifyContent="center">
              <CheckIcon />
            </Box>
            <TypographyWithSpace variant="body1">
              Your passord was reset successfully.{" "}
              <Link component={NavLink} exact to="/">
                Continue to login.
              </Link>
            </TypographyWithSpace>
          </>
        ) : (
          <>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Reset Password
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Enter a new password
            </Typography>

            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(6, "Must be at least 6 characters")
                  .max(255)
                  .required("Required"),
                confirmPassword: Yup.string().when("password", {
                  is: (val: any) => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                  ),
                }),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await dispatch(
                    resetPassword(parseInt(userId), values.password, token)
                  );
                  setShowSuccessMessage(true);
                } catch (error) {
                  const message =
                    error.message ||
                    "Invalid password reset token. Please request a new password reset.";

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                  <ButtonWithLoader
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                  >
                    Submit
                  </ButtonWithLoader>
                </form>
              )}
            </Formik>
            <TypographyWithSpace mt={6} variant="body1">
              <Link component={NavLink} exact to="/">
                Go back to login
              </Link>
            </TypographyWithSpace>
          </>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet title="Reset Password" />
      {showSuccessMessage ? (
        <>
          <Box display="flex" justifyContent="center">
            <CheckIcon />
          </Box>
          <TypographyWithSpace variant="body1">
            If you entered an existing email address, you will shortly receive
            an email with link to change your password.
          </TypographyWithSpace>
          <TypographyWithSpace mt={3} variant="body1">
            <Link component={NavLink} exact to="/">
              Go back to login
            </Link>
          </TypographyWithSpace>
        </>
      ) : (
        <>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your email to reset your password
          </Typography>

          <Formik
            initialValues={{
              email: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(
                  requestResetPassword({
                    email: values.email,
                  })
                );
                setShowSuccessMessage(true);
              } catch (error) {
                const message = error.message || "Something went wrong.";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <ButtonWithLoader
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                >
                  Submit
                </ButtonWithLoader>
              </form>
            )}
          </Formik>
        </>
      )}
    </Wrapper>
  );
}

export default ResetPassword;
