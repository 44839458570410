import React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Button as MuiButton,
  Typography,
  Card as MuiCard,
  CardContent,
  CardActionArea,
  CardMedia as MuiCardMedia,
  CardHeader,
  Box,
  SvgIcon as MuiSvgIcon,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";
import { useState } from "react";
import { CreateCompanyDialog } from "../admin/companies/CreateCompanyDialog";
import { CreateCourseDialog } from "../admin/CreateCourseDialog";
import { ListAlt } from "@material-ui/icons";
import { Users } from "react-feather";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const CardMedia = styled(MuiCardMedia)`
  height: 140px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const SvgIcon = styled(MuiSvgIcon)`
  color: #00245d;
  height: 120px;
  width: 120px;
  margin-top: 10px;
  padding: 20px;
`;

function AdminDashboard() {
  const [createCompanyOpen, setCreateCompanyOpen] = useState(false);
  const [createCourseOpen, setCreateCourseOpen] = useState(false);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
          <Card>
            <CardHeader title="Actions" />
            <CardContent>
              <Box display="flex" flexDirection="column">
                <Button
                  variant="contained"
                  mb={3}
                  color="primary"
                  onClick={() => setCreateCompanyOpen(true)}
                >
                  Create Company
                </Button>
                <Button
                  variant="contained"
                  mb={3}
                  color="primary"
                  onClick={() => setCreateCourseOpen(true)}
                >
                  Create Course
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={3}>
          <Card mb={6}>
            <CardActionArea component={Link} to="/admin/companies">
              <CardMedia title="Courses">
                <SvgIcon>
                  <Users />
                </SvgIcon>
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Companies
                </Typography>
                <Typography component="p">See companies.</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={3}>
          <Card mb={6}>
            <CardActionArea component={Link} to="/courses">
              <CardMedia title="Courses">
                <SvgIcon>
                  <ListAlt />
                </SvgIcon>
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Courses
                </Typography>
                <Typography component="p">
                  See available courses and prices.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <CreateCompanyDialog
        open={createCompanyOpen}
        onClose={() => setCreateCompanyOpen(false)}
      />
      <CreateCourseDialog
        open={createCourseOpen}
        onClose={() => setCreateCourseOpen(false)}
      />
    </>
  );
}

export default AdminDashboard;
