import { AxiosRequestConfig } from "axios";
import {
  getJwtFromLocalStorage,
  removeJwtFromLocalStorage,
} from "../utils/localStorageHelper";
import { HttpClient } from "./HttpClient";
import { IsValidJwt } from "../utils/jwt";

export class ProtectedHttpClient extends HttpClient {
  public constructor() {
    super();
    this._initializeRequestInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    const jwtToken = getJwtFromLocalStorage();
    // token invalid, redirect to login.
    if (!jwtToken || !IsValidJwt(jwtToken)) {
      removeJwtFromLocalStorage();
      window.location.replace("/");
      return config;
    }
    config.headers["Authorization"] = `Bearer ${jwtToken}`;
    return config;
  };
}
